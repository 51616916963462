import styled from 'styled-components';
import { colors, breakPoints } from 'utils/variables';
import { Link } from 'gatsby';
import hexToRGBA from 'utils/hexToRGBA';
import Container from '../Container/Container';

export const StyledWrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: ${colors.white};
  box-shadow: 0 0 1rem ${hexToRGBA(colors.black, 0.15)};
`;

export const StyledContent = styled.div``;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8rem;
  transition: height 300ms ease;

  @media (min-width: ${breakPoints.tablet}) AND (max-width: ${breakPoints.desktop}) {
    height: ${({ isFolded }) => (isFolded ? '9rem' : '12rem')};
    width: 90%;
  }

  @media (min-width: ${breakPoints.desktop}) {
    height: ${({ isFolded }) => (isFolded ? '9rem' : '13.1rem')};
  }
`;

export const StyledLogoHref = styled(Link)`
  margin: 0 auto;
  transform: translateX(2rem);

  @media (min-width: ${breakPoints.tablet}) {
    margin: 0 0 0 1rem;
    transform: translateX(-1.4rem);
  }

  @media (min-width: ${breakPoints.desktop}) {
    transform: translateX(2rem);
  }
`;

export const StyledLogo = styled.svg`
  height: 6rem;
  transition: height 300ms ease;

  @media (min-width: ${breakPoints.tablet}) {
    height: ${({ props }) => (props.isFolded ? '8rem' : 'unset')};
  }
`;

export const StyledHamburger = styled.div`
  display: inline-block;
  position: relative;
  z-index: 10;

  svg {
    width: 4rem;
    height: 4rem;
  }
`;
