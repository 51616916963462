import styled from 'styled-components';
import { colors, fontWeight, breakPoints } from 'utils/variables';
import { Link } from 'gatsby';
import { ButtonReverse } from 'components/_shared/Button/Button';

export const StyledMainMenu = styled.div`
  position: absolute;
  width: 100%;
  height: ${({ isOpen }) => (isOpen ? 'calc(100vh - 8rem)' : '0rem')};
  top: 8rem;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  transition: height 150ms ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};

  @media (min-width: ${breakPoints.tablet}) {
    position: relative;
    width: auto;
    height: auto;
    flex-direction: row;
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;
  }
`;

export const StyledMenuLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  padding: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${colors.veryDarkBlue};
  font-weight: ${fontWeight.regular};
  margin: 1.6rem 0;
  letter-spacing: 0.05rem;
  transition: border 150ms ease;
  border-bottom: 0.1rem solid transparent;
  min-width: 5rem;
  text-align: center;

  @media (min-width: ${breakPoints.tablet}) {
    margin: 0 1.2rem;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  &:after {
    display: block;
    content: attr(data-text);
    font-weight: ${fontWeight.bold};
    height: 0;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }

  &:hover {
    border-bottom: 0.1rem solid ${colors.veryDarkBlue};
  }

  &.current {
    font-weight: ${fontWeight.bold};
  }
`;

export const StyledButton = styled(ButtonReverse)`
  margin: 1rem 0 8rem 0;

  @media (min-width: ${breakPoints.tablet}) {
    margin: 0 0 0 1rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    margin: 0 0 0 5.9rem;
  }
`;
