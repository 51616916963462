import styled from 'styled-components';
import { colors, fontWeight, breakPoints } from 'utils/variables';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  width: 100%;
  height: 5rem;
  padding: 1.5rem 0;
  background-color: ${colors.barBlue};
  position: absolute;
  bottom: 0;
  transition: height 300ms ease;

  @media (min-width: ${breakPoints.tablet}) {
    display: none;
  }
`;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;

  @media (min-width: ${breakPoints.tablet}) {
    display: none;
  }
`;

export const StyledLink = styled.a`
  color: ${colors.white};
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: ${fontWeight.medium};
  text-decoration: none;
  transition: border 150ms ease;
  border-bottom: 0.1rem solid transparent;

  &:hover {
    border-bottom: 0.1rem solid ${colors.white};
  }

  @media (min-width: ${breakPoints.tablet}) {
    display: none;
  }
`;
