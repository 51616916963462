import styled from 'styled-components';
import { colors, fontWeight, breakPoints } from 'utils/variables';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  width: 100%;
  height: ${({ isFolded }) => (isFolded ? '0rem' : '4rem')};
  padding: 0;
  background-color: ${colors.barBlue};
  bottom: 0;
  transition: height 300ms ease;
  position: relative;
  display: none;

  @media (min-width: ${breakPoints.tablet}) {
    display: block;
  }
`;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 100%;

  @media (min-width: ${breakPoints.tablet}) AND (max-width: ${breakPoints.desktop}) {
    width: 90%;
  }
`;

export const StyledLink = styled.a`
  color: ${colors.white};
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: ${fontWeight.medium};
  text-decoration: none;
  transition: border 150ms ease;
  border-bottom: 0.1rem solid transparent;

  &:hover {
    border-bottom: 0.1rem solid ${colors.white};
  }
`;
