import styled from 'styled-components';
import { breakPoints, fontWeight, colors } from 'utils/variables';

export const StyledWrapper = styled.div`
  margin-bottom: 1rem;
  padding-top: 0.2rem;

  @media (min-width: ${breakPoints.tablet}) {
    margin-bottom: 0;
    max-width: 35%;
    text-align: right;
  }

  @media (min-width: ${breakPoints.desktop}) {
    max-width: 30%;
  }
`;

export const StyledTitle = styled.h3`
  padding: 0;
  margin: 0;
  font-size: 1.8rem;
  line-height: 4.6rem;
  font-weight: ${fontWeight.medium};
  color: ${colors.veryDarkBlue};
`;

export const StyledDescription = styled.span`
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: ${fontWeight.light};
  color: ${colors.grayCobalt};

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 1.6rem;
    line-height: 3.2rem;
  }

  a {
    color: ${colors.grayCobalt};
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      color: ${colors.grayCobalt};
      text-decoration: underline;
    }
  }
`;
