import React from 'react';
import PropTypes from 'prop-types';
import SEO from 'templates/SEO/SEO';
import GlobalStyle from 'templates/GlobalStyle/GlobalStyle';
import MainMenu from 'components/_shared/Header/Header';
import Footer from 'components/_shared/Footer/Footer';
import Cookies from './components/Cookies/Cookies';
import StyledContent from './Layout.styled';

const Layout = ({ children, metaTitle, metaDescription, indexing }) => (
  <>
    <GlobalStyle />
    <SEO metaTitle={metaTitle} metaDescription={metaDescription} indexing={indexing} />
    <main>
      <MainMenu />
      <StyledContent>{children}</StyledContent>
      <Footer />
      <Cookies />
    </main>
  </>
);

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  indexing: PropTypes.bool,
};

Layout.defaultProps = {
  metaTitle: '',
  metaDescription: '',
  indexing: true,
};

export default Layout;
