import styled from 'styled-components';
import { colors, breakPoints } from 'utils/variables';
import hexToRGBA from 'utils/hexToRGBA';

export const StyledWrapper = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  margin: 0;
  padding: 1rem 0;
  background-color: ${hexToRGBA(colors.black, 0.88)};
  z-index: 100;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 1rem;

  @media (min-width: ${breakPoints.tablet}) {
    padding: 0;
  }
`;

export const StyledContent = styled.div`
  color: ${colors.white};
  font-size: 1.1rem;

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 1.1rem;
  }

  a {
    color: ${colors.white};

    &:hover {
      color: ${hexToRGBA(colors.white, 0.75)};
    }
  }
`;

export const StyledClose = styled.div`
  color: ${colors.white};
  font-size: 3rem;
  line-height: 3rem;
  width: 3rem;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: ${hexToRGBA(colors.white, 0.75)};
  }
`;
