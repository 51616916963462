import React from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper, StyledTitle, StyledDescription } from './Contact.styled';

const Contact = ({ contact }) => (
  <StyledWrapper>
    <StyledTitle>Kontakt</StyledTitle>
    <StyledDescription dangerouslySetInnerHTML={{ __html: contact.description }} />
  </StyledWrapper>
);

Contact.propTypes = {
  contact: PropTypes.shape({
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default Contact;
