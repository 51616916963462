import React from 'react';
import { StyledWrapper, StyledContainer, StyledLink } from './MobileBar.styled';

const MobileBar = () => (
  <StyledWrapper>
    <StyledContainer>
      <StyledLink href="mailto:poczta@biuro-hossa.eu" title="email">
        <b>email:</b> poczta@biuro-hossa.eu
      </StyledLink>
      <StyledLink href="tel:+48502646169" title="telefon">
        <b>tel:</b> +48 502 64 61 69
      </StyledLink>
    </StyledContainer>
  </StyledWrapper>
);

export default MobileBar;
