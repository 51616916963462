import React, { useState, useEffect } from 'react';
import Logo from 'images/logo.inline.svg';
import Hamburger from 'images/hamburger.inline.svg';
import Close from 'images/close.inline.svg';
import useWindowSize from 'utils/useWindowSize';
import useWindowOffset from 'utils/useWindowOffset';
import DesktopBar from './components/DesktopBar/DesktopBar';
import MainMenu from './components/MainMenu/MainMenu';
import { StyledWrapper, StyledContent, StyledContainer, StyledLogoHref, StyledLogo, StyledHamburger } from './Header.styled';

const Header = () => {
  const [isOpenMenu, openMenu] = useState(false);
  const [isFolded, fold] = useState(false);
  const [show, setShow] = useState(true);
  const windowSize = useWindowSize();
  const windowOffset = useWindowOffset();

  const menuOpen = (e) => {
    openMenu(!isOpenMenu);
    e.target.blur();
  };

  useEffect(() => {
    if (windowOffset.y > 170) {
      fold(true);
    } else {
      fold(false);
    }
  }, [windowOffset]);

  useEffect(() => {
    const handleResize = () => openMenu(false);
    if (windowSize !== 'mobile') {
      window.addEventListener('resize', handleResize);
      setShow(true);
    } else {
      setShow(false);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize]);

  return (
    <StyledWrapper>
      <DesktopBar isFolded={isFolded} />
      <StyledContent>
        <StyledContainer isFolded={isFolded}>
          <StyledLogoHref to="/" title="logo">
            <StyledLogo props={{ isFolded }} as={Logo} />
          </StyledLogoHref>
          <MainMenu isOpen={isOpenMenu} />
          {!show ? <StyledHamburger onClick={(e) => menuOpen(e)}>{isOpenMenu ? <Close /> : <Hamburger />}</StyledHamburger> : ''}
        </StyledContainer>
      </StyledContent>
    </StyledWrapper>
  );
};

export default Header;
