import { useState, useEffect } from 'react';
import throttle from './throttle';

const useWindowOffset = (throttleWait = 30) => {
  const [x, setX] = useState(typeof window !== `undefined` ? window.pageXOffset : 0);
  const [y, setY] = useState(typeof window !== `undefined` ? window.pageYOffset : 0);
  const handleScroll = throttle(() => {
    setX(window.pageXOffset);
    setY(window.pageYOffset);
  }, throttleWait);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [x, y]);
  return {
    x,
    y,
  };
};
export default useWindowOffset;
