import styled from 'styled-components';
import { breakPoints, fontWeight, colors } from 'utils/variables';
import { Link } from 'gatsby';

export const StyledWrapper = styled.div`
  margin-bottom: 2rem;
  padding-top: 0.1rem;

  @media (min-width: ${breakPoints.tablet}) {
    max-width: 25%;
    margin-bottom: 0;
  }

  @media (min-width: ${breakPoints.desktop}) {
    max-width: 30%;
  }
`;

export const StyledTitle = styled.h3`
  padding: 0;
  margin: 0;
  font-size: 1.8rem;
  line-height: 4.6rem;
  font-weight: ${fontWeight.medium};
  color: ${colors.veryDarkBlue};
`;

export const StyledList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledListElement = styled(Link)`
  font-size: 1.4rem;
  line-height: 3.2rem;
  font-weight: ${fontWeight.light};
  color: ${colors.grayCobalt};
  text-decoration: none;

  &:hover {
    color: ${colors.grayCobalt};
    text-decoration: underline;
  }
`;
