import styled from 'styled-components';
import { breakPoints } from 'utils/variables';

export const StyledWrapper = styled.div`
  position: relative;
  max-width: 80rem;
  margin: auto;

  @media (min-width: ${breakPoints.tablet}) {
    max-width: unset;
    min-height: 52.1rem;
  }
`;

export const StyledContent = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${breakPoints.tablet}) {
    flex-direction: row;
    padding-top: 13.5rem;
  }
`;

export const StyledBackgroundShape = styled.svg`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -18rem);
  width: 100vw;
  height: calc(100% + 18rem);
  pointer-events: none;

  @media (min-width: ${breakPoints.tablet}) {
    transform: translate(-50%, 0);
    height: 100%;
  }
`;
