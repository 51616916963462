import React, { useState, useEffect } from 'react';
import Container from 'components/_shared/Container/Container';
import { StyledWrapper, StyledContainer, StyledContent, StyledClose } from './Cookies.styled';

const Cookies = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      setShow(!localStorage.getItem('cookies'));
    }
  });

  const closeCookies = () => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('cookies', 'accepted');
      setShow(false);
    }
  };

  return (
    <StyledWrapper show={show}>
      <StyledContainer as={Container}>
        <StyledContent
          dangerouslySetInnerHTML={{
            __html:
              'Nasza strona korzysta z ciasteczek, aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie. Zobacz <a title="politykę prywatności" href="/polityka-prywatnosci/">politykę prywatności</a>.',
          }}
        />
        <StyledClose onClick={closeCookies}>&times;</StyledClose>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default Cookies;
