import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Title, Meta } from 'react-head';

const SEO = ({ meta, metaTitle, metaDescription, indexing }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `,
  );

  const alternativeTitle = `${site.siteMetadata.title} | ${site.siteMetadata.description}`;
  const alternativeDescription = site.siteMetadata.description;
  const sitePath = typeof window !== 'undefined' ? window.location.pathname : '/';
  const url = `${site.siteMetadata.siteUrl}${sitePath}`;
  const metaIndexing = indexing
    ? []
    : [
        {
          name: 'robots',
          content: 'noindex',
        },
    ];

  return (
    <>
      <Title>{metaTitle || alternativeTitle}</Title>
      <Meta name='description' content={metaDescription || alternativeDescription} />
      <Meta name='keywords' content='biuro rachunkowe, rybnik, podatki, płace, w rybniku, rachunkowe, księgowość, księgowa, zus, podatkowe, firmy, rybnickie' />
      <Meta property='og:title' content={metaTitle || alternativeTitle} />
      <Meta property='og:description' content={metaDescription || alternativeDescription} />
      <Meta property='og:url' content={url} />
      <Meta property='og:type' content='website' />
      <Meta name='twitter:card' content='summary' />
      <Meta name='twitter:creator' content={site.siteMetadata.author} />
      <Meta name='twitter:title' content={metaTitle || alternativeTitle} />
      <Meta name='twitter:description' content={metaDescription || alternativeDescription} />
      <Meta name='og:image' content={`${site.siteMetadata.siteUrl}/assets/ogimage.jpg`} />
      <Meta name='og:image:alt' content={site.siteMetadata.siteUrl} />
      <Meta name='twitter:image' content={`${site.siteMetadata.siteUrl}/assets/ogimage.jpg`} />
      {metaIndexing.map(({ name, content }) => <Meta name={name} content={content} />)}
      {meta.map(props => <Meta {...props}/>)}
    </>
  );
};

SEO.defaultProps = {
  lang: 'pl',
  meta: [],
  metaTitle: null,
  metaDescription: null,
  indexing: true,
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  indexing: PropTypes.bool,
};

export default SEO;
