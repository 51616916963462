import React from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper, StyledTitle, StyledList, StyledListElement } from './Links.styled';

const getLink = (link) => (
  <StyledListElement key={link.text} to={link.url}>
    {link.text}
  </StyledListElement>
);

const Links = ({ links }) => (
  <StyledWrapper>
    <StyledTitle>Menu</StyledTitle>
    <StyledList>{links.map(getLink)}</StyledList>
  </StyledWrapper>
);

Links.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Links;
