const throttle = (func, wait) => {
  let timeout = null;

  const later = () => {
    func();
    timeout = null;
  };

  return () => {
    if (!timeout) {
      timeout = setTimeout(later, wait);
    }
  };
};

export default throttle;
