import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StyledButton, StyledMainMenu, StyledMenuLink } from './MainMenu.styled';
import MobileBar from '../MobileBar/MobileBar';

const links = [
  {
    to: '/',
    text: 'Strona główna',
  },
  {
    to: '/o-nas/',
    text: 'O nas',
  },
  {
    to: '/wazne-informacje/',
    text: 'Ważne informacje',
  },
  {
    to: '/uslugi/',
    text: 'Usługi',
  },
  {
    to: '/cennik/',
    text: 'Cennik',
  },
];

const getLink = (link) => (
  <StyledMenuLink key={link.text} to={link.to} activeClassName="current" data-text={link.text}>
    {link.text}
  </StyledMenuLink>
);

const MainMenu = ({ isOpen }) => (
  <StyledMainMenu isOpen={isOpen}>
    <MobileBar />
    {links.map(getLink)}
    <StyledButton as={Link} to="/kontakt/">
      Kontakt
    </StyledButton>
  </StyledMainMenu>
);

MainMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default MainMenu;
