import styled from 'styled-components';
import { colors, fontWeight, breakPoints } from 'utils/variables';

export const StyledButton = styled.button`
  display: inline-block;
  border-radius: 0.2rem;
  border: 0.1rem solid ${colors.peach};
  background-color: ${colors.peach};
  color: ${colors.peachMilk};
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  font-weight: ${fontWeight.bold};
  margin: 0;
  letter-spacing: 0.07rem;
  transition: box-shadow 150ms ease;
  font-size: 1.2rem;
  line-height: 1.8rem;
  padding: 0.8rem 2.8rem;
  box-shadow: 0.3rem 0.3rem 0 0 ${colors.butter};

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 1.1rem 3.4rem;
    box-shadow: 0.4rem 0.4rem 0 0 ${colors.butter};
  }

  &:hover {
    background-color: ${colors.peachMilk};
    color: ${colors.peach};
  }
`;

export const StyledButtonReverse = styled(StyledButton)`
  background-color: ${colors.peachMilk};
  color: ${colors.peach};
  box-shadow: none;
  font-weight: ${fontWeight.medium};

  @media (min-width: ${breakPoints.desktop}) {
    box-shadow: none;
  }

  &:hover {
    background-color: ${colors.peach};
    color: ${colors.peachMilk};
  }
`;
