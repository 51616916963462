import styled from 'styled-components';
import { breakPoints } from 'utils/variables';

const Container = styled.div`
  margin: 0 auto;
  width: calc(100% - 2rem);

  @media (min-width: ${breakPoints.tablet}) {
    width: 86rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    width: 120rem;
  }
`;

export default Container;
