import { createGlobalStyle } from 'styled-components';
import { fontWeight } from 'utils/variables';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%; /* =1rem */
    font-family: Poppins, sans-serif;
    font-weight: ${fontWeight.medium};
    -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
  }

  button,
  input,
  textarea {
    font-family: Poppins, sans-serif;
    outline: none;
  }

  body {
    margin: 0;
  }

  main {
    overflow-x: hidden;
    position: relative;
  }
`;

export default GlobalStyle;
