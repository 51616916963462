import styled from 'styled-components';
import { breakPoints } from 'utils/variables';

const StyledContent = styled.div`
  margin-top: 8rem;

  @media (min-width: ${breakPoints.tablet}) {
    margin-top: 11rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    margin-top: 17.1rem;
  }
`;

export default StyledContent;
