import React from 'react';
import BackgroundShape from 'images/footer-background.inline.svg';
import ContentData from 'content/footer.json';
import { StyledWrapper, StyledContent, StyledBackgroundShape } from './Footer.styled';
import Container from '../Container/Container';
import Copyright from './components/Copyright/Copyright';
import Info from './components/Info/Info';
import Links from './components/Links/Links';
import Contact from './components/Contact/Contact';

const Footer = () => (
  <StyledWrapper>
    <StyledBackgroundShape as={BackgroundShape} />
    <Container>
      <StyledContent>
        <Info info={ContentData.info} />
        <Links links={ContentData.links} />
        <Contact contact={ContentData.contact} />
      </StyledContent>
      <Copyright />
    </Container>
  </StyledWrapper>
);

export default Footer;
