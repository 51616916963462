import styled from 'styled-components';
import { breakPoints, fontWeight, colors } from 'utils/variables';

export const StyledWrapper = styled.div`
  position: relative;
  margin: 0 4.5rem;
  border-top: 0.2rem solid ${colors.peach};
  margin-top: 2.5rem;
`;

export const StyledContent = styled.span`
  display: block;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: ${fontWeight.medium};
  color: ${colors.veryDarkBlue};
  width: 100%;
  text-align: center;
  margin-top: 1.5rem;
  padding-bottom: 1.5rem;

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 1.6rem;
    line-height: 2.3rem;
    margin-top: 3.1rem;
    padding-bottom: 0;
  }
`;
