import styled from 'styled-components';
import { breakPoints, fontWeight, colors } from 'utils/variables';

export const StyledWrapper = styled.div`
  margin-bottom: 2rem;

  @media (min-width: ${breakPoints.tablet}) {
    max-width: 30%;
    margin-bottom: 0;
  }
`;

export const StyledTitle = styled.h3`
  padding: 0;
  margin: 0 0 1.4rem 0;
  font-size: 3.2rem;
  line-height: 4.6rem;
  font-weight: ${fontWeight.bold};
  color: ${colors.darkBlue};
`;

export const StyledDescription = styled.span`
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: ${fontWeight.light};
  color: ${colors.grayCobalt};

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
`;
