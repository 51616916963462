import React from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper, StyledTitle, StyledDescription } from './Info.styled';

const Info = ({ info }) => (
  <StyledWrapper>
    <StyledTitle>HOSSA</StyledTitle>
    <StyledDescription dangerouslySetInnerHTML={{ __html: info.description }} />
  </StyledWrapper>
);

Info.propTypes = {
  info: PropTypes.shape({
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default Info;
