// ----------------------------------------------------------------
// Copy image to "./src/images/" and use like:
// <Image src="example-image.png" />;
// ----------------------------------------------------------------

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Image = ({ src, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile {
        nodes {
          extension
          name
          svg {
            content
            dataURI
          }
          relativePath
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  `);

  const match = useMemo(() => data.allFile.nodes.find(({ relativePath }) => src === relativePath), [data, src]);

  if (match.extension === 'svg') {
    return <img src={match.svg.dataURI} {...props} />;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return match !== undefined ? <Img fluid={match.childImageSharp.fluid} Tag="div" {...props} /> : null;
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  props: PropTypes.arrayOf(PropTypes.any),
};

Image.defaultProps = {
  props: [],
};

export default Image;
