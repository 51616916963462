import React from 'react';
import { StyledWrapper, StyledContent } from './Copyright.styled';

const Copyright = () => (
  <StyledWrapper>
    <StyledContent>Copyright @ {new Date().getFullYear()}, Biuro Rachunkowe HOSSA</StyledContent>
  </StyledWrapper>
);

export default Copyright;
