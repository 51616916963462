// Colors
export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  error: '#CC5D5D',
  success: '#719B6D',
  darkBlue: '#043250',
  barBlue: '#194D6F',
  grayCobalt: '#545B6A',
  gray: '#AAAAAA',
  peach: '#C89017',
  peachMilk: '#FFFBF4',
  butter: '#FFEDCB',
  veryDarkBlue: '#000D2A',
  frozenSilver: '#ADB1BB',
  silver: '#707070',
};

// Screen size - min-width
export const breakPointsNumber = {
  tablet: 992,
  desktop: 1300,
  fullsize: 1600,
};

export const breakPoints = {
  tablet: `${breakPointsNumber.tablet}px`,
  desktop: `${breakPointsNumber.desktop}px`,
  fullsize: `${breakPointsNumber.fullsize}px`,
};

// Font weight
// export const fontWeight = {
//   thin: 100,
//   extraLight: 200,
//   light: 300,
//   regular: 400,
//   medium: 500,
//   semiBold: 600,
//   bold: 700,
//   extraBold: 800,
//   black: 900,
// };

export const fontWeight = {
  light: 400,
  regular: 500,
  medium: 600,
  bold: 700,
};
