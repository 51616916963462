import { useState, useEffect } from 'react';
import { breakPointsNumber } from './variables';

const useWindowSize = () => {
  const isClient = typeof window === 'object';

  const getSize = () => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  };

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    const handleResize = () => {
      setWindowSize(getSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (windowSize.width >= breakPointsNumber.fullsize) {
    return 'fullsize';
  }

  if (windowSize.width >= breakPointsNumber.desktop) {
    return 'desktop';
  }

  if (windowSize.width >= breakPointsNumber.tablet) {
    return 'tablet';
  }
  return 'mobile';
};

export default useWindowSize;
