import React from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper, StyledContainer, StyledLink } from './DesktopBar.styled';

const DesktopBar = ({ isFolded }) => (
  <StyledWrapper isFolded={isFolded}>
    <StyledContainer>
      <StyledLink href="mailto:poczta@biuro-hossa.eu" title="email">
        <b>email:</b> poczta@biuro-hossa.eu
      </StyledLink>
      <StyledLink href="tel:+48502646169" title="telefon">
        <b>tel:</b> +48 502 64 61 69
      </StyledLink>
    </StyledContainer>
  </StyledWrapper>
);

DesktopBar.propTypes = {
  isFolded: PropTypes.bool.isRequired,
};

export default DesktopBar;
